import React from 'react'
import { Analytics } from '@vercel/analytics/react'
import { Poppins } from '@next/font/google'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider } from 'next-themes'
import '@src/theme/globalStyles'

const poppins = Poppins({
  weight: '400',
  display: 'swap',
  subsets: ['latin'],
  variable: '--font-poppins',
})

export default function App(props: AppProps) {
  const { Component, pageProps } = props

  return (
    <React.Fragment>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <title>Vu Thanh Nguyen Profile</title>
        <meta name="description" content="Vu Thanh Nguyen Profile" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <style jsx global>{`
        html {
          font-family: ${[poppins.style?.fontFamily, 'Customized Verdana'].join(
            ', '
          )};
        }
      `}</style>
      <Analytics></Analytics>
      <ThemeProvider>
        <Component {...pageProps} />
      </ThemeProvider>
    </React.Fragment>
  )
}

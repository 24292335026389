import { css } from '@linaria/core'
import { only, from } from '@utils/media'

export const globals = css`
  :global() {
    /* Fallback font */
    @font-face {
      font-family: 'Customized Verdana';
      src: local(Verdana);
      size-adjust: 100%;
      ascent-override: 97%;
      descent-override: 23%;
      line-gap-override: 31%;
    }

    *::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: transparent;
    }

    *::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f90;
    }

    :root {
      /*--background: #f6bf00;
      --foreground: #000000;*/
      --background: #1f203a;
      --foreground: #fee715ff;

      ${from('md')} {
        font-size: 18px;
      }

      --max-width: 72rem;
    }

    [data-theme='dark'] {
      --background: #1f203a;
      --foreground: #fee715ff;
    }

    html {
      box-sizing: border-box;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-display: optional;
      line-height: 1.1;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
    }

    /* Remove default margin */
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    figure,
    blockquote,
    dl,
    dd {
      margin: 0;
    }

    /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
    ul[role='list'],
    ol[role='list'] {
      list-style: none;
    }

    /* Set core root defaults */
    html:focus-within {
      scroll-behavior: smooth;
    }

    /* A elements that don't have a class get default styles */
    a:not([class]) {
      text-decoration-skip-ink: auto;
    }

    /* Inherit fonts for inputs and buttons */
    input,
    button,
    textarea,
    select {
      font: inherit;
    }

    body {
      min-height: 100vh;
      text-rendering: optimizeSpeed;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background: var(--background);
      color: var(--foreground);
    }

    /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
      html:focus-within {
        scroll-behavior: auto;
      }

      *,
      *::before,
      *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
      }
    }

    main {
      max-width: 72rem;
      padding: 0 2rem;
      margin: auto;
    }

    ${only('xs')} {
      main {
        padding: 2rem 0.5rem;
      }
    }
  }
`

export default globals
